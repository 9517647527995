import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Blog = ({ data, location }) => {
  if (!data || !data.allContentfulPost || data.allContentfulPost.length === 0)
    return "No posts yet"
  const posts = data.allContentfulPost.edges
  return (
    <Layout location={location} title={""}>
      <h2 className="blog-title">
        <Link to="/" style={{ textDecoration: "none" }}>
          ←
        </Link>
      </h2>
      <h1 className="blog-title">{"Michael Olsen's Tech Blog"}</h1>
      <SEO title="Blog" />
      <div className="blog-links">
        {posts.map(({ node }) => {
          const title = node.title || node.slug
          return (
            <article
              key={node.slug}
              itemScope
              itemType="http://schema.org/Article"
            >
              <Link className="blog-post-link" to={node.slug} itemProp="url">
                <div>
                  <Img
                    fluid={node.image?.fluid}
                    className="blog-list-img"
                    alt=""
                  />
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <h2
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {title}
                  </h2>
                  <section>
                    <p
                      style={{
                        color: `gray`,
                      }}
                    >
                      {node.subtitle}
                    </p>
                  </section>
                </div>
              </Link>
            </article>
          )
        })}
      </div>
    </Layout>
  )
}

Blog.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost {
      edges {
        node {
          title
          subtitle
          author
          slug
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
